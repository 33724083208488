<template>
  <div class="privacyPolicy_warp">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
        <span>返回</span>
      </div>
      <div class="title">隐私政策</div>
      <div class="rightBtn"></div>
    </div>
    <div class="content">
      <img class="logo" :src="logoUrl" />
      <div class="url">{{ downloadUrl }}</div>
      <p class="textContent">
        本隐私条款说明当您使用我们的网站、手机应用程式或其他线上产品和服务（以下统称为 「服务」
        ），或者当您以其他方式与我们互动时，您的资讯将如何被收集并使用于「软妹社」。我们可能会随时更改此隐私权条款，因此我们鼓励您在使用服务时，随时查看并了解我们最新的隐私权条款，以便帮助保护您的资讯隐私。
      </p>
      <p class="subTitle">您所提供给我们的资讯</p>
      <p class="textContent">
        我们会收集您直接提供给我们的资讯。例如，我们会收集所有您创建帐户时的资讯，以及使用服务来发送或接收的讯息（包含通过我们的服务「软妹社」所拍摄的照片或影片，以及通过客服或与其他方式与我们沟通时所产生的讯息。）我们可能收集的资讯包含用户名称、帐户密码、电子邮件地址、电话号码、年龄、性别以及任何您选择提供的其他资讯。
      </p>
      <p class="textContent">
        我们诚心地建议您提供您拥有版权的内容（例如讯息、照片、影片、标题）。我们无法防范他人储存您的内容（例如拍摄截图），如果您不希望有心人士储存您的某些内容，那么您不应使用
        软妹社 发送该内容。
      </p>

      <p class="subTitle">我们在您使用服务时所收集的资讯</p>
      <p class="textContent">当您使用我们的服务时，我们会自动收集您的以下资讯：</p>
      <p class="textIndent">
        •
        使用纪录：当您通过我们的服务发送或接收讯息，我们会收集这些讯息资讯，包括时间、日期、发件人和收件人等资讯。我们还会收集您发送与接收的讯息数量，以及您较常传送讯息的朋友名单。我们也会收集您使用我们的服务时的资讯，包含您的浏览器类型、语言、使用时间、IP位址等。
      </p>
      <p class="textIndent">• 装置资讯：我们会收集您使用我们的服务时使用的装置资讯，包括装置类型、操作系统和版本</p>
      <p class="textIndent">
        •
        设备标识码、电话号码和行动网络资讯。此外，本服务在经过您的同意之下，可取用您装置中的电话簿和影像存储应用程式，以方便支援您在使用本服务时的功能。位置资讯：在经过您的同意之下，我们会收集有关您的装置位置资讯，以方便支援您在使用本服务时的功能。
      </p>
      <p class="textIndent">• 数据追踪：我们会使用各种数据追踪技术来收集资讯，来帮助我们来改进我们的服务和您的使用体验。</p>

      <p class="subTitle">我们从其他人取得的资讯</p>
      <p class="textContent">除了您直接提供给我们的资讯外，我们还会从其他人那里获得有关您的资讯，包括：</p>
      <p class="textIndent">
        • 其他使用者：其他人可能在使用我们的服务时提供有关您的资讯。例如，如果其他使用者就您的事情与我们联系，我们可能会从他们那里收集有关您的资讯
      </p>
      <p class="textIndent">
        •
        社群媒体：您可能会使用自己的社群媒体登入资讯（例如Facebook或twitter登入资讯）来建立和登入您的软妹社帐号。因此您无须再记住另一组使用者名称和密码，并可通过您的社群媒体帐号与我们分享一些资讯。
      </p>
      <p class="textIndent">
        •
        其他合作伙伴：我们可能会从合作伙伴那里取得有关您的资讯，例如，软妹社广告刊登在合作伙伴的网站和平台上。（这种情况下，他们可能会将活动成功的详细资讯传递给我们）。
      </p>

      <p class="subTitle">我们使用您资讯</p>
      <p class="textContent">
        我们使用您资讯的主要原因是为了提供和改善我们的服务。此外，我们使用您的资讯来维护您的安全，并提供您可能感兴趣的广告。请继续阅读有关我们使用您资讯的各项原因详细说明，以及实际范例。
      </p>

      <p class="subTitle">管理您的帐号，并提供我们的服务给您</p>
      <p class="textIndent">• 建立和管理您的帐号</p>
      <p class="textIndent">• 为您提供客户支援，和回应您的要求</p>
      <p class="textIndent">• 完成您的交易</p>
      <p class="textIndent">• 就我们的服务（包括订单管理和计费）与您沟通</p>
      <p class="textIndent">
        •
        将您使用的各种装置连结起来，以便让您在所有装置上享受我们的服务，并获得一致性体验。我们通过连结装置和浏览器资料来提供一致性体验（例如当您在不同装置上登入帐号时），或使用部分或完整的IP位址、浏览器版本和有关您装置的类似资料，来辨识和连结装置。
      </p>

      <p class="subTitle">为您提供最优的软妹社服务</p>
      <p class="textIndent">• 让您注册新的软妹社功能和app，并显示您的个人资料。</p>
      <p class="textIndent">• 在这些新功能和app中管理您的帐号改善我们的服务和开发新服务</p>
      <p class="textIndent">• 管理焦点团体和问卷调查</p>
      <p class="textIndent">
        • 针对使用者行为进行研究和分析，以改善我们的服务和内容（例如，我们可能会决定变更外观和感觉，或什至根据使用者行为，大幅修改特定功能）
      </p>
      <p class="textIndent">• 开发新功能和服务（例如，我们可能会决定根据使用者的要求，进一步建立一个以兴趣为依据的新功能）</p>

      <p class="subTitle">防止、发现和打击诈欺、其他非法或未经授权的活动</p>
      <p class="textIndent">• 处理正在发生，或被指控的不当行为（无论是否发生在平台上）</p>
      <p class="textIndent">• 分析资料，以更加了解这类活动并制定对策</p>
      <p class="textIndent">• 保留诈欺活动相关资料，以防止这类活动再次发生</p>

      <p class="subTitle">我们如何保护您的资讯</p>
      <p class="textContent">
        我们致力防止您的个人资讯遭未经授权的存取或变更、披露或销毁。与所有科技公司一样，尽管我们采取措施保护您的资讯，但是我们无法承诺，而且您亦不应期望，您的个人资讯会始终安全无虞。针对可能存在的漏洞和攻击，我们定期监视我们的系统，并定期审查我们的资讯收集、储存和处理做法，以更新我们的实体、技术和组织安全措施。如果我们怀疑或发现任何违反安全的行为，我们可以暂停您使用所有或部分服务，恕不另行通知。如果您认为您的帐号或资讯不再安全，请立即通知我们。
      </p>

      <p class="subTitle">儿童的隐私</p>
      <p class="textContent">
        我们的服务仅限18岁（含）以上的使用者使用。我们不允许18岁以下的使用者使用我们的平台，而且我们不会在知情的情况下收集18岁以下人士的个人资讯。如果您怀疑任何使用者的年龄低于18岁，请通过服务提供的通报机制，向我们通报此疑虑。
      </p>

      <p class="subTitle">其他</p>

      <p class="subTitle">帐户资讯</p>
      <p class="textContent">您可以在App中编辑您的帐号设定，更新您的个人资讯。如您有任何问题，请在 与我们联系。</p>

      <p class="subTitle">推播通知</p>
      <p class="textContent">
        软妹社会发送推播通知或系统提醒到您的装置。您可以在您的装置设定中更改通知设定（iOS）,或者通过App更改通知设定（Android）。
      </p>
      <p class="textContent">
        本公司提供活动资讯通知之服务（包括但不限于以推播、简讯或电子邮件之方式不定期发送活动资讯）。若您不希望收到任何活动资讯，可随时联系本公司客服，本公司客服将协助您取消活动资讯通知之设定。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logoUrl: require('@/assets/png/appLogo.png'),
      downloadUrl: '',
    };
  },
  created() {
    this.getUrl();
  },
  methods: {
    getUrl() {
      let appConfig = this.$store.getters.appConfig;
      appConfig.sourceList.forEach((i) => {
        if (i.type == 'GROUPEMAIl') {
          this.eMail = i.domain[0].url;
        } else if (i.type == 'BusinessCooperation') {
          this.cooperation = i.domain[0].url;
        } else if (i.type == 'LAND') {
          this.downloadUrl = i.domain[0].url;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.privacyPolicy_warp {
  height: 100%;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(217, 217, 217);
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }

      span {
        font-size: 18px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .logo {
    width: 90px;
    height: 90px;
    display: block;
    margin: 20px auto 10px;
  }

  .url {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }

  .textIndent {
    text-indent: 14px;
    // margin-bottom: 10px;
  }

  .textContent {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .subTitle {
    font-size: 18px;
    font-weight: 600;
    margin: 18px 0 10px;
  }

  .content {
    height: calc(100% - 50px);
    overflow-y: auto;
    padding: 0 16px 16px 16px;
    -webkit-overflow-scrolling: touch;

    h2 {
      font-size: 22px;
      padding-top: 30px;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
    }

    .subTitle {
      font-size: 18px;
      font-weight: 600;
      margin: 22px 0 14px;
    }

    .textContent {
      margin-bottom: 10px;
      font-size: 16px;
    }

    .textIndent {
      text-indent: 14px;
      font-size: 16px;
      // margin-bottom: 10px;
    }

    h2::before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 20px;
      background-image: linear-gradient(135deg, #c85a49, #9a1f1f);
      border-radius: 10px;
      margin-right: 6px;
    }

    p {
      line-height: 22px;
      font-size: 12px;
    }

    .down {
      padding-bottom: 100px;

      h2 {
        padding-bottom: 20px;
      }

      .dwon_box {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .down_btn {
          padding: 10px 30px;
          background: linear-gradient(to right, #5f2c82, #49a09d);
          box-shadow: 0px 1px 3px rgba(#49a09d, 0.5);
          border-radius: 20px;
          margin-right: 20px;
          font-size: 16px;
        }

        .shu {
          background: linear-gradient(to right, #556270, #ff6b6b);
          box-shadow: 1px 1px 3px rgba(#ff6b6b, 0.5);
        }
      }
    }
  }
}
</style>
